import React from "react"
import "../styles/mindbody.scss"
import accountStyles from "../components/account.module.scss"
import Layout from '../components/layout-default'
import Breadcrumbs from "../components/breadcrumbs";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Privates",
    link: "privates",
  },
];

const Privates = () => (
  <Layout
    title="Get personalized Salsa & Bachata lessons | RF Dance"
    description="Do you want a personalized dance instructor to teach you Salsa and Bachata dance? Book your private dance lessons at RF Dance for an unforgettable dance journey!" 
    pathname="account"
    noHero="no-hero"
    className="pages">
    <div className="container">
      <Breadcrumbs crumbs={crumbs} />
      <iframe 
        className={accountStyles.iframe}
        title="RF Dance Private Lesson Scheduler"
        id="mindbody_branded_web_cart_modal" 
        height="800"
        frameBorder="0" 
        marginWidth="0" 
        marginHeight="0" 
        scrolling="yes"
        src="https://app.acuityscheduling.com/schedule.php?owner=17766906" />
    </div>
  </Layout>
)

export default Privates